
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react";
import axios from 'axios';
import logo from "./imgs/rouparia-carioca2.jpg"
import getCookie from "./getCookie";
import './Home.css';

const Home = () => {

    const [inputs, setInputs] = useState(
        {
            email: "",
            password: ""
        }
    )

    const navigate = useNavigate()

    useEffect(() => { 
        axios.post(`https://${process.env.REACT_APP_HOST}/router`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
        .then(response => {
            navigate('/table')
        })
        .catch(error => {
            if (error.response.data.msg == "Token has expired")
              axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
              .then(response => {
                navigate('/table')
              })
              .catch(refused => {})
          })

    }, [])

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value
    
        setInputs(values => ({...values, [name]: value}))
    }

    const login = () =>{
        axios.post(`https://${process.env.REACT_APP_HOST}/login`, {username: inputs.email, password: inputs.password}, {withCredentials: true})
        .then(response => {
            navigate('/table')
        })
        .catch(error => {
            alert(error.response.data)
        })
    }

    
    
    return (
        <div className="main">
            <img src={logo} alt="Logo" />
            <br></br>
            <div>
                nome:
                <input
                    name= 'email'
                    type='email'
                    onChange={handleChange}
                    />
                senha:      
                 <input
                    name= 'password'
                    type='password'
                    onChange={handleChange}

                    />
                <input
                    type="button"
                    onClick={login}
                    value="login"
                    /> 

            </div>
        </div>
    )
}

export default Home