import dateString from "./dateString";

export const COLUMNS = [
    {
        header: 'In/Out',
        accessorKey: 'receber_pagar',
        sortingFn: 'alphanumeric',
    },
    {
        header: 'Fornecedor',
        accessorKey: 'nome_cliente',
    },
    {
        header: 'VALOR',
        accessorKey: 'valor_fatura',
        aggregatedCell: (props) => {
            // if (props.cell.id == "status:Baixadas_valor_fatura" || props.cell.id == "status:Em Aberto_valor_fatura")
            //     return ""  
            let num = Math.round(props.getValue() * 100) / 100;
            if (props.cell.row.groupingColumnId == "data_baixa")
            {   
                let sum = 0;
                props.row.getParentRow().subRows.forEach(element => {
                    if(new Date(element.groupingValue) <= new Date(props.row.groupingValue))
                    {
                        const val = Math.round(element._groupingValuesCache.valor_fatura * 100) / 100;
                        sum += val
                    }
                })
                let saldo = props.table.options.state.initial_balance + sum;
                let num_color;
                let saldo_color;
                if (num < 0)
                {
                    num *= -1;
                    num_color = "red";
                }
                else
                    num_color = "black";
                if (saldo < 0)
                {
                    saldo *= -1;
                    saldo_color = "red";
                }
                else
                    saldo_color = "blue";
                return (<div><span>Resultado do dia: </span><span style={{color: num_color}}>{num.toLocaleString('pt-BR')}</span><span> | Saldo no dia: </span><span style={{color: saldo_color}}>{saldo.toLocaleString('pt-BR')}</span></div>)
            }
            if (num < 0)
            {
                num *= -1;
                return (<span style={{color: 'red'}}>{num.toLocaleString('pt-BR')}</span>)
            }
            return num.toLocaleString('pt-BR');
        },
        aggregationFn: (columnId, leafRows, childRows) => {
            let sum = 0
            leafRows.forEach(element => {
                if (element.original.receber_pagar === "Entradas")
                    sum += element.original.valor_fatura
                else
                    sum -= element.original.valor_fatura
            });
            return sum;
        },
    },
    {
        header: 'Data de Vencimento',
        accessorKey: 'data_vencimento',
        sortingFn: 'datetime',
        cell: (props) => {
            if (props.getValue() === null)
                return ""
            return dateString(props.getValue());
        },
        aggregatedCell: props => "",
    },
    {
        header: 'Data de Baixa',
        accessorKey: 'data_baixa',
        sortingFn: 'datetime',
        cell: (props) => {
            if (props.getValue() === undefined || props.getValue() === null)
                return ""
            return dateString(props.getValue());
        },
        aggregatedCell: props => "",
    },
    {
        header: 'Loja',
        accessorKey: 'cnpj_emp',
    },
    {
        header: 'Código da Fatura',
        accessorKey: 'codigo_fatura',
        aggregatedCell: props => ""
    },
    {
        header: 'Código do Histórico',
        accessorKey: 'cod_historico',
        aggregatedCell: props => ""
    },
    {
        header: 'Observação',
        accessorKey: 'observacao',
    },
    {
        header: 'Status',
        accessorKey: 'status',
    },
];