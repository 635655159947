import './Table.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import { useState, useEffect, useMemo, useReducer } from "react";
import { COLUMNS } from './columns';
import getCookie from './getCookie';
import dateString from "./dateString";
import {
  GroupingState,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getCoreRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'

function Table() {

  const [data, setData] = useState([]);
  const [current, setCurrent] = useState([]);
  const [role, setRole] = useState(false);
  const [register, setRegister] = useState(false);
  
  const [result, setResult] = useState(0);
  const [opened, setOpened] = useState(0);
  const [opened_expenses, setOpenedExpenses] = useState(0);
  const [opened_incomes, setOpenedIncomes] = useState(0);
  const [closed_expenses, setClosedExpenses] = useState(0);
  const [closed_incomes, setClosedIncomes] = useState(0);
  
  const [prediction, setPrediction] = useState(0);
  const [last_prediction, setLastPrediction] = useState("");
  
  const [initial_balance, setInitialBalance] = useState(0);
  const [current_balance, setCurrentBalance] = useState(0);
  const [projected_balance, setProjectedBalance] = useState(0);
  const [atelier_balance, setAtelierBalance] = useState(0);
  const [last_atelier_balance, setLastAtelierBalance] = useState("");

  const rerender = useReducer(() => ({}), {})[1]
  const navigate = useNavigate()
  const [totals, setTotal] = useState(
    {
      income_total: 0,
      expense_total: 0,
      opened_income_total: 0,
      closed_income_total: 0,
      opened_expense_total: 0,
      closed_expense_total: 0,
    }
  );
  const [edit, setEdit] = useState(
    {
      prediction: false,
      initial_balance: false, 
      atelier_balance: false, 
    }
  );
  
  const [inputs, setInputs] = useState(
    {
        email: "",
        password: "",
        prediction: prediction,
        initial_balance: initial_balance,
        atelier_balance: atelier_balance,
        year: new Date().getFullYear(),
    }
  )

  const createDate = (m) => {
    return new Date(`${m+1}/01/${inputs.year}`)
  }

  const [selectedDate, setSelectedDated] = useState(createDate(new Date().getMonth()))

  const reqTotals = () =>{
    axios.post(`https://${process.env.REACT_APP_HOST}/total`, {year: inputs.year}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        setTotal(response.data)
        setRole(response.data.role)
      })
      .catch(error => {
        if (error.response.data.msg === "Token has expired")
          axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
          .then(response => {
            reqTable(formatDate(new Date()))
          })
          .catch(refused => navigate("/"))
        else
          navigate("/")
      })
  }

  const reqTable = (month) =>{
    axios.post(`https://${process.env.REACT_APP_HOST}/get_table`, {month: month}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        response.data.table.forEach(i => {
          if (i.data_baixa != null)
            i.data_baixa = new Date(i.data_baixa)
          if (i.data_vencimento != null)
            i.data_vencimento = new Date(i.data_vencimento)
          });
        setData(response.data.table)
        setPrediction(response.data.prediction)
        setLastPrediction(dateString(new Date(response.data.prediction_time)))
        setInputs(values => ({...values, ['prediction']: response.data.prediction}))
        setInitialBalance(response.data.balance)
        setInputs(values => ({...values, ['atelier_balance']: response.data.atelier_balance}))
        setAtelierBalance(response.data.atelier_balance)
        setLastAtelierBalance(dateString(new Date(response.data.atelier_time)))
        setOpenedIncomes(response.data.opened_income_total)
        setClosedIncomes(response.data.closed_income_total)
        setOpenedExpenses(response.data.opened_expense_total)
        setClosedExpenses(response.data.closed_expense_total)
        setOpened(response.data.opened_income_total - response.data.opened_expense_total)
        setResult(response.data.closed_income_total - response.data.closed_expense_total)
        setInputs(values => ({...values, ['initial_balance']: response.data.balance}))
        setCurrent(month)
        setEdit(
          {
            prediction: false,
            initial_balance: false, 
          }
        );
      })
      .catch(error => {
        if (error.response.data.msg === "Token has expired")
          axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
          .then(response => {
            reqTable(month)
          })
          .catch(refused => navigate("/"))
        else
          navigate("/")
      })
  }

  const formatDate = (d) => {
    return `${String(d.getMonth() + 1).padStart(2, '0')}/${inputs.year}`;
  }

  useEffect(
    () => { reqTable(formatDate(selectedDate)); reqTotals() }, [inputs.year]
  )

  useEffect(
    () => { setCurrentBalance(initial_balance + result) }, [result, initial_balance]
  )

  useEffect(
    () => { setProjectedBalance(calculate_projected_balance()) }, [initial_balance, prediction, atelier_balance]
  )
  
  const columns = useMemo(() => COLUMNS, []);
  const [grouping, setGrouping] = useState(['status', 'receber_pagar', 'cnpj_emp', 'nome_cliente'])
  const [months, setMonths] = useState(["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"])
  const [sorting, setSorting] = useState([{id: "status", asc: true},{id: "receber_pagar", asc: true},{id: "cnpj_emp", asc: true},{id: "nome_cliente", asc: true}])
  const [columnFilters, setColumnFilters] = useState([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      grouping,
      sorting,
      initial_balance,
      columnFilters,
    },
    onGroupingChange: setGrouping,
    onSortingChange: setSorting,
    onInitialBalanceChange: setInitialBalance,
    onColumnFiltersChange: setColumnFilters,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  })

  const makeMonths = () =>{
    return months.map( (v, i) => {return <input type="button" onClick={() => {const d = createDate(i); setSelectedDated(d); reqTable(formatDate(d))}} value={v} key={i} style={current === formatDate(createDate(i)) ? {backgroundColor: 'yellow'} : {}} /> } )
  }

  const logout = () => {
    axios.post(`https://${process.env.REACT_APP_HOST}/logout`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        navigate("/")
      })
  }

const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value

    setInputs(values => ({...values, [name]: value}))
}

const handleEdit = (name) => {

  setEdit(values => ({...values, [name]: !values[name]}))
}

const savePrediction = (month) =>{
  const num = Number(inputs.prediction)
  axios.post(`https://${process.env.REACT_APP_HOST}/save_prediction`, {month: month, prediction: num}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        setPrediction(num)
        setLastPrediction(dateString(new Date()))
      })
      .catch(error => {
        if (error.response.data.msg === "Token has expired")
          axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
          .then(response => {
            setPrediction(num)
            setLastPrediction(dateString(new Date()))
          })
          .catch(refused => navigate("/"))
        else
          navigate("/")
      })
}

const saveAtelier = (month) =>{
  const num = Number(inputs.atelier_balance)
  axios.post(`https://${process.env.REACT_APP_HOST}/save_atelier`, {month: month, balance: num}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        setAtelierBalance(num)
        setLastAtelierBalance(dateString(new Date()))
      })
      .catch(error => {
        if (error.response.data.msg === "Token has expired")
          axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
          .then(response => {
            setAtelierBalance(num)
            setLastAtelierBalance(dateString(new Date()))
          })
          .catch(refused => navigate("/"))
        else
          navigate("/")
      })
}

const saveBalance = (month) =>{
  const num = Number(inputs.initial_balance)
  axios.post(`https://${process.env.REACT_APP_HOST}/save_balance`, {month: month, balance: num}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
      .then(response => {
        setInitialBalance(num)
      })
      .catch(error => {
        if (error.response.data.msg === "Token has expired")
          axios.post(`https://${process.env.REACT_APP_HOST}/refresh`, {}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_refresh_token')}})
          .then(response => {
            setInitialBalance(num)
          })
          .catch(refused => navigate("/"))
        else
          navigate("/")
      })
}

const _register = () =>{
  axios.post(`https://${process.env.REACT_APP_HOST}/register`, {username: inputs.email, password: inputs.password}, {withCredentials: true, headers: {'X-CSRF-TOKEN': getCookie('csrf_access_token')}})
  .then(response => {
      alert(response.data.registered)
  })
  .catch(error => {
    alert(error.response.data.registered)
  })
  setInputs(values => ({...values, ["email"]: "", ["password"]: ""}))
}

const editable_info = (title, hook, element, func) => {
    return(
      <span style={{position: "absolute", left: "5%"}}>
          {title}: {!edit[element] && <span>{hook.toLocaleString('pt-BR')} </span>} {edit[element] && <span><input name={element} type='number' value={inputs[element]} onChange={handleChange}/></span>}
        {(!edit[element] && role) && <span style={{cursor: "pointer"}} onClick={() => handleEdit(element)}>
            editar
        </span>}

        {edit[element] && <span style={{cursor: "pointer"}} onClick={() => {handleEdit(element); func(current)}}>
              salvar 
        </span>}

        {edit[element] && <span style={{cursor: "pointer"}} name={element} onClick={() => {handleEdit(element); setInputs(values => ({...values, [element]: hook}))}}>
              {" cancelar"} 
        </span>}
          
      </span>
    )
}

const calculate_projected_balance = () =>{
  const ret = initial_balance + (closed_incomes - closed_expenses) + (opened_incomes - opened_expenses) + prediction + atelier_balance;
  return ret
}

const YearSelect = () => {
  const options = [];

  const current_year = new Date().getFullYear()

  for (let i = 2020; i <= current_year; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
  }
  return (
      <div>
          <label htmlFor="number-select">Ano:</label>
          <select id="number-select" value={inputs.year} name='year' onChange={handleChange}>
              {options}
          </select>
      </div>
  );
};


  return (
    
    <div className="p-2">
      <div>
        <input type="button" onClick={() => logout()} value="logout"/>
        {role && <input type="button" onClick={() => {setRegister(!register); setInputs(values => ({...values, ["email"]: "", ["password"]: ""}))}} value={!register ? "Registrar" : "Cancelar"}/>}
        {register && <div>
          <br></br>
            nome:     
            <input
                name= 'email'
                type='email'
                onChange={handleChange}
            />
            senha:      
             <input
                name= 'password'
                type='password'
                onChange={handleChange}

            />
            <input
                type="button"
                onClick={() => {_register(); setRegister(false)}}
                value="Confirm"
            />
        </div>}
        {YearSelect()}
        <br></br>
      </div>
      <div style={{fontSize: "1vw", alignContent: "center"}}>
        <div style={{position: "relative", height: "20px", width: "100%"}}>
          <span style={{position: "absolute", left: "5%"}}>Total de Saídas no Ano: {totals.expense_total.toLocaleString('pt-BR')}</span>
          <span style={{position: "absolute", left: "40%"}}>Valores Pagos no Ano: {totals.closed_expense_total.toLocaleString('pt-BR')}</span>
          <span style={{position: "absolute", right: "5%"}}>a Pagar até o Fim do Ano: {totals.opened_expense_total.toLocaleString('pt-BR')}</span>
        </div>
        <div style={{position: "relative", height: "20px", width: "100%"}}>
          <span style={{position: "absolute", left: "5%"}}>Total de Entradas no Ano: {totals.income_total.toLocaleString('pt-BR')}</span>
          <span style={{position: "absolute", left: "40%"}}>Valores Recebidos no Ano: {totals.closed_income_total.toLocaleString('pt-BR')}</span>
          <span style={{position: "absolute", right: "5%"}}>a Receber até o Fim do Ano: {totals.opened_income_total.toLocaleString('pt-BR')}</span>
        </div>
      </div>
      <br></br>
      <div style={{position: "relative", height: "20px", width: "100%", fontSize: "1vw", alignContent: "center"}}>
        {editable_info("Previsão de Entradas no Mês", prediction, "prediction", savePrediction)}
        <span style={{position: "absolute", left: "40%"}}>Resultado do Mês até Agora: <span style={result < 0 ? {color: "red"} : {color: "blue"}}>{result < 0 ? (result * -1).toLocaleString('pt-BR') : result.toLocaleString('pt-BR')}</span></span>
      <br></br>
        <div>
          <span style={{position: "absolute", left: "10%", fontSize: "0.7vw"}}> Última modificação: {last_prediction}</span>
       </div>
      </div>
      <br></br>
      <br></br>
      <div style={{position: "relative", height: "20px", width: "100%", fontSize: "1vw", alignContent: "center"}}>
        {editable_info("Valor Pedidos a Desconsiderar", atelier_balance, "atelier_balance", saveAtelier)}
        <br></br>
        <div>
          <span style={{position: "absolute", left: "10%", fontSize: "0.7vw"}}> Última modificação: {last_atelier_balance}</span>
        </div>
      </div>
      <br></br>
      <div style={{position: "relative", height: "20px", width: "100%", fontSize: "1vw", alignContent: "center"}}>
         {editable_info("Saldo Inicial", initial_balance, "initial_balance", saveBalance)}
         <span style={{position: "absolute", left: "40%"}}>Saldo do Mês até Hoje: <span style={current_balance < 0 ? {color: "red"} : {color: "blue"}}>{current_balance < 0 ? (current_balance * -1).toLocaleString('pt-BR') : current_balance.toLocaleString('pt-BR')}</span></span>
         <span style={{position: "absolute", right: "5%"}}>Saldo Projetado no fim do Mês: <span style={projected_balance < 0 ? {color: "red"} : {color: "blue"}}>{projected_balance < 0 ? (projected_balance * -1).toLocaleString('pt-BR') : projected_balance.toLocaleString('pt-BR')}</span></span>
      </div>
      <br></br>
      <br></br>
      <div>
        {makeMonths()}
      </div>
      <div>
        <input type="button" onClick={() => {setGrouping(['status', 'receber_pagar', 'cnpj_emp', 'nome_cliente']); setSorting([{id: "status", asc: true},{id: "receber_pagar", asc: true},{id: "cnpj_emp", asc: true},{id: "nome_cliente", asc: true}]); setColumnFilters([])}} value="Por Loja" style={sorting[2].id === "cnpj_emp" ? {backgroundColor: 'yellow'} : {}} />
        <input type="button" onClick={() => {setGrouping(['status', 'receber_pagar', 'nome_cliente', 'cnpj_emp']); setSorting([{id: "status", asc: true},{id: "receber_pagar", asc: true},{id: "nome_cliente", asc: true},{id: "cnpj_emp", asc: true}]); setColumnFilters([])}} value="Por Fornecedor" style={sorting[2].id === "nome_cliente" ? {backgroundColor: 'yellow'} : {}} />
        <input type="button" onClick={() => {setGrouping(['status', 'receber_pagar', 'cod_historico','cnpj_emp', 'nome_cliente']); setSorting([{id: "status", asc: true},{id: "receber_pagar", asc: true},{id: "cod_historico", asc: true}]); setColumnFilters([])}} value="Por Histórico" style={sorting[2].id === "cod_historico" ? {backgroundColor: 'yellow'} : {}} />
        <input type="button" onClick={() => {setGrouping(['status', 'data_baixa','cnpj_emp', 'receber_pagar', 'nome_cliente']); setSorting([{id: "data_baixa", asc: true},{id: "cnpj_emp", asc: true},{id: "receber_pagar", asc: true},{id: "nome_cliente", asc: true}]); setColumnFilters([{id: "status", value: 'Em Aberto'}]); setColumnFilters([{id: "status", value: 'Baixadas'}])}} value="Por Baixa" style={sorting[0].id === "data_baixa" ? {backgroundColor: 'yellow'} : {}} />
        <input type="button" onClick={() => {setGrouping(['status', 'data_vencimento','receber_pagar','cnpj_emp', 'nome_cliente']); setSorting([{id: "data_vencimento", asc: true},{id: "cnpj_emp", asc: true},{id: "receber_pagar", asc: true},{id: "nome_cliente", asc: true}]); setColumnFilters([{id: "status", value: 'Em Aberto'}])}} value="Por Vencimento" style={sorting[0].id === "data_vencimento" ? {backgroundColor: 'yellow'} : {}} />
      </div>
      <div className="h-2" />
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} /*onClick={header.column.getToggleSortingHandler()}*/>
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    )}
                    {/* {
                      {asc: " ▲", desc: " ▼"}[
                        header.column.getIsSorted() ?? null
                      ]
                    } */}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            if (row.id === "status:Baixadas" && row.getIsExpanded() == false && sorting[0].id === "data_baixa")
              row.toggleExpanded()
            if (row.id === "status:Em Aberto" && row.getIsExpanded() == false && sorting[0].id === "data_vencimento")
              row.toggleExpanded()
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      {...{
                        key: cell.id,
                        style: {
                          background: cell.getIsGrouped() && (cell.row.id.includes('receber_pagar:Entradas'))
                            ? '#0aff0082'
                            : cell.getIsGrouped() && (cell.row.id.includes('receber_pagar:Saídas'))
                            ? '#ff000042'
                            : cell.getIsGrouped() && (cell.row.id.includes('status:Baixadas'))
                              ? '#6FB0FA58'
                            : cell.getIsGrouped()
                              ? '#ffa50078'
                            : cell.getIsAggregated() && (cell.row.id.includes('status:Baixadas'))
                              ? '#6FB0FA58'
                            : cell.getIsAggregated()
                              ? '#ffa50078'
                            : cell.getIsPlaceholder()
                              ? '#e6e6e6'
                            : 'white',
                        },
                      }}
                    >
                      {cell.getIsGrouped() ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                          <button
                            {...{
                              onClick: row.getToggleExpandedHandler(),
                              style: {
                                cursor: row.getCanExpand()
                                  ? 'pointer'
                                  : 'normal',
                              },
                            }}
                          >
                            {row.getIsExpanded() ? '▼' : '►'}{' '}
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}{' '}
                            ({row.subRows.length})
                          </button>
                        </>
                      ) : cell.getIsAggregated() ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        flexRender(
                          cell.column.columnDef.aggregatedCell ??
                            cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Table;
