
import Table from './components/Table';
import Home from './components/Home';
import {BrowserRouter,Routes,Route} from "react-router-dom";

const App = () => {
    return (
        <div>
            
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/table" element={<Table/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App